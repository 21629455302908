





































import { Component, Vue } from 'vue-property-decorator';
import VButton from '@/components/VButton.vue';
import { Action } from 'vuex-class';
import * as types from '@/store/account/types';
import SmallCard from '@/components/cards/SmallCard.vue';
import FormField from '@/utilities/validation/formfield';
import ValidatedForm from '@/utilities/validation/validatedform';

@Component({
  components: { VButton, SmallCard },
})
export default class ResetPassword extends Vue {

  @Action('resetPassword', { namespace: 'account' }) private resetPassword!: types.ResetPasswordAction;

  private serverError: boolean = false;
  private submitting: boolean = false;
  private passwordField = new FormField((value, dirty) => value.length > 5, '');
  private repeatField = new FormField((value, dirty) => value === this.passwordField.value, '');

  private passwordForm = new ValidatedForm({
    password: this.passwordField,
    repeat: this.repeatField,
  });

  get passwordFormValid() {
    return this.passwordForm.fieldValidity();
  }

  private submit() {
    if (this.passwordForm.formValid()) {
      const token: string = this.$route.params.resetToken;
      const uidb64: string = this.$route.params.uid;
      this.submitting = true;
      this.resetPassword({
        password: this.passwordField.value,
        token,
        uidb64,
      })
      .then(() => {
        this.$router.push({ name: 'login' });
      }).catch(() => {
        this.serverError = true;
      }).finally(() => {
        this.submitting = false;
      });
    }
  }
}
